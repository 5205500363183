body {
  margin: 0;
  padding: 0;
  background-color: #08415c;
  color: white;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(49, 49, 49) rgba(151, 151, 151, 0.082);
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 12px;
}
*::-webkit-scrollbar-track {
  background: rgba(151, 151, 151, 0.082);
}
*::-webkit-scrollbar-thumb {
  background-color: rgb(49, 49, 49);
  border-radius: 20px;
  border: 3px solid rgba(151, 151, 151, 0.082);
}

h1 {
  margin: 45px 0px 20px;
}
ul {
  margin-bottom: 40px;
}

ul li {
  margin: 5px;
}

.HeaderSection {
  background-color: #08415c;
  color: white;
  width: 1100px;
  margin: 0 auto;
}

textarea {
  width: 1100px;
  outline: none;
}

.formSection {
  width: 1100px;
  margin: 6px 0 30px;
}

table {
  margin: 0 auto;
}

.button {
  background-color: #1a936f;
  outline: none;
  color: white;
  border: none;
  padding: 5px 10px;
  margin: 2px 4px 2px 2px;
  width: 100px;
}
.button:hover {
  background-color: rgb(21, 122, 92);
  cursor: pointer;
}
.button:active {
  background-color: rgb(21, 122, 92);
  cursor: pointer;
  transform: scale(1.06);
}

.userAgentOutputContainer {
  background-color: #1a936f;
  width: 1100px;
  margin: 0 auto;
}

.textContainer {
  display: inline-block;
  position: relative;
}

.textContainer textarea {
  background-color: whitesmoke;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: #00000000 2px solid;
  padding: 4px 10px;
  font-size: 15px;
  box-sizing: border-box;
  color: rgb(68, 67, 67);
  resize: none;
}

textarea:focus {
  border: #1a936f 2px solid;
}

.clear-btn {
  border: none;
  color: rgb(7, 57, 80);
  background: none;
  font-size: 30px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: ease 0.5s all;
  position: absolute;
  top: 5px;
  right: 10px;
  outline: none;
  font-size: 25px;

  font-family: Lucida Sans Unicode, Arial Unicode MS;
}

.clear-btn:hover {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  text-decoration: none;
  cursor: pointer;
}

.clear-btn:active {
  color: #1a936f;
}

.btn-agnt {
  color: white;
  background-color: #08415c;
  border: none;
  padding: 5px 15px;
  width: 90px;
  outline: none;
}
.btn-agnt:hover {
  background-color: rgb(7, 57, 80);
  cursor: pointer;
}

.btn-agnt:active {
  transform: scale(1.06);
}

/*HYPERLINKS COLOURS*/
a:link {
  color: #1eac81;
}

a:visited {
  color: #1eac81;
}

a:hover {
  color: rgb(23, 131, 99);
}

/** Styling Table **/
.agent-tbl {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 20px;
}

.agent-tbl td,
.agent-tbl th {
  padding: 10px;
}

.agent-tbl tr:nth-child(even) {
  background-color: #188665;
}

.agent-tbl tr:hover {
  background-color: rgb(21, 122, 92);
}

.agent-tbl th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgb(19, 107, 81);
  color: white;
  border-bottom: white 1px solid;
}

.agent-tbl th:last-child {
  text-align: center;
}
.agent-tbl td:last-child {
  text-align: center;
}
/** End Table **/
